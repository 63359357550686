import {Component, Input, Output} from '@angular/core';
import {NgFor, NgIf} from '@angular/common';
import {RouterLink} from '@angular/router';
import {ReplaySubject} from 'rxjs';
import Recipe from '@app/model/recipe.model';
import {PathNames} from '@app/app.routes';
import {ScaleYieldFormChangedEvent} from '@app/view-recipe/types/scale-yield-form-changed-event';
import {ScaleYieldFormDto} from '@app/view-recipe/types/scale-yield-form-dto';
import {ScaleYieldComponent} from '../scale-yield/scale-yield.component';
import {DescriptionComponent} from '@app/view-recipe/description/description.component';

@Component({
  selector: 'app-view-recipe',
  templateUrl: './view-recipe.component.html',
  styleUrls: ['./view-recipe.component.css'],
  standalone: true,
  imports: [NgIf, NgFor, ScaleYieldComponent, RouterLink, DescriptionComponent],
})
export class ViewRecipeComponent {
  protected readonly PathNames = PathNames;

  @Output()
  public readonly yieldListItemChanged =
    new ReplaySubject<ScaleYieldFormChangedEvent>(1);

  @Input({ required: true })
  public recipe: Recipe;

  @Input({ required: true })
  public scaleYieldFormDTOs: ScaleYieldFormDto[];

  public isSourceALink(): boolean {
    return this.recipe.source.indexOf('http') === 0;
  }
}
