<form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup)">
  <!-- prevent form to be submitted on ENTER -->
  <button type="submit" disabled class="hidden" aria-hidden="true"></button>
  <div id="recipe-forms">
    <div class="title-row">
      <input
        type="text"
        class="form-field"
        title="Title"
        id="title-input"
        formControlName="title"
        placeholder="Titel"
      />

      <input
        type="text"
        class="form-field"
        title="Übersetzer Titel"
        id="title-translation-input"
        formControlName="titleTranslation"
        placeholder="Übersetzer Titel"
      />
    </div>

    <div id="source-form">
      <input
        type="text"
        class="form-field"
        title="Quelle"
        id="source-input"
        formControlName="source"
        placeholder="Quelle"
      />
    </div>

    <app-yield-list
      id="yield-list"
      [formArray]="yieldForms"
    ></app-yield-list>

    <app-keywords-container
      id="keyword-container"
      [keywordsForms]="keywordForms"
    ></app-keywords-container>

    <div id="description-form">
      <textarea
        id="description-input"
        class="form-field"
        title="Beschreibung"
        formControlName="description"
        placeholder="Beschreibung"
      >
      </textarea>
      <div id="description-input-markdown-info">
        (Für formatierung kann hier
        <a href="https://en.wikipedia.org/wiki/Markdown" target="_blank">
          Markdown
        </a>
        verwendet werden)
      </div>
    </div>

    <div id="cooking-time-form">
      <input
        type="number"
        class="form-field"
        title="Kochzeit"
        id="cooking-time-input"
        formControlName="cookingTime"
        placeholder="Kochzeit"
      />
    </div>

    <div id="preparation-time-form">
      <input
        type="number"
        class="form-field"
        title="Vorbereitungszeit"
        id="preparation-time-input"
        formControlName="preparationTime"
        placeholder="Vorbereitungszeit"
      />
    </div>
  </div>

  <app-step-list [formArray]="stepForms"></app-step-list>

  <button type="submit" class="button submit-recipe-button">Speichern</button>
</form>
