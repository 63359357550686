import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ReactiveFormsModule, UntypedFormArray, UntypedFormGroup} from '@angular/forms';
import {StepListComponent} from '../step-list/step-list.component';
import {KeywordsContainerComponent} from '../keywords-container/keywords-container.component';
import {YieldListComponent} from '../yield-list/yield-list.component';

@Component({
    selector: 'app-edit-recipe',
    templateUrl: './recipe.component.html',
    styleUrls: ['./recipe.component.css'],
    standalone: true,
    imports: [ReactiveFormsModule, YieldListComponent, KeywordsContainerComponent, StepListComponent]
})
export class RecipeComponent {
  @Output()
  public saveRecipe = new EventEmitter<UntypedFormGroup>();

  @Input({ required: true })
  public formGroup: UntypedFormGroup;

  get stepForms(): UntypedFormArray {
    return this.formGroup.get('steps') as UntypedFormArray;
  }

  get keywordForms(): UntypedFormArray {
    return this.formGroup.get('keywords') as UntypedFormArray;
  }

  get yieldForms(): UntypedFormArray {
    return this.formGroup.get('yield') as UntypedFormArray;
  }

  public onSubmit(formGroup: UntypedFormGroup): void {
    this.saveRecipe.emit(formGroup);
  }
}

