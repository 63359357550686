import {isDevMode} from '@angular/core';
import {provideEffects} from '@ngrx/effects';
import {provideRouterStore, routerReducer} from '@ngrx/router-store';
import {MetaReducer, provideState, provideStore} from '@ngrx/store';
import {bootstrapApplication} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {provideRouter, withHashLocation} from '@angular/router';
import {provideStoreDevtools} from '@ngrx/store-devtools';
import {AppComponent} from '@app/app.component';
import {provideServiceWorker} from '@angular/service-worker';
import {provideNoopAnimations} from '@angular/platform-browser/animations';
import {NativeEffects} from '@app/store/effects/native.effects';
import {ViewEffects} from '@app/store/effects/view.effects';
import {RouterEffects} from '@app/store/effects/router.effects';
import {RecipeEffects} from '@app/store/effects/recipe.effects';
import {MeasuringUnitEffects} from '@app/store/effects/measuring-unit.effects';
import {KeywordEffects} from '@app/store/effects/keyword.effects';
import {IngredientEffects} from '@app/store/effects/ingredient.effects';
import {AppEffects} from '@app/store/effects/app.effects';
import {HttpErrorInterceptor} from '@app/interceptors/http-error.interceptor';
import {routes} from '@app/app.routes';
import {measuringUnitFeature} from '@app/store/reducers/measuring-unit.reducer';
import {viewRecipePageFeature} from '@app/store/reducers/view-recipe-page.reducer';
import {listRecipesPageFeature} from '@app/store/reducers/list-recipes-page.reducer';
import {recipeFeature} from '@app/store/reducers/recipe.reducer';
import {keywordFeature} from '@app/store/reducers/keyword.reducer';
import {ingredientFeature} from '@app/store/reducers/ingredient.reducer';
import {localStorageSyncReducer} from '@app/store/local-storage-sync-reducer/local-storage-sync-reducer';

const metaReducers: MetaReducer[] = [localStorageSyncReducer];

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(routes, withHashLocation()),
    provideStore(
      { router: routerReducer },
      {
        metaReducers,
      },
    ),
    provideState(ingredientFeature),
    provideState(keywordFeature),
    provideState(measuringUnitFeature),
    provideState(recipeFeature),
    provideState(viewRecipePageFeature),
    provideState(listRecipesPageFeature),
    provideEffects([
      AppEffects,
      IngredientEffects,
      KeywordEffects,
      MeasuringUnitEffects,
      RecipeEffects,
      RouterEffects,
      ViewEffects,
      NativeEffects,
    ]),
    provideRouterStore(),
    provideStoreDevtools({
      maxAge: 10,
      logOnly: !isDevMode(),
      autoPause: true,
      trace: false,
      connectInZone: true,
    }),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideNoopAnimations(),
  ],
})
  // eslint-disable-next-line unicorn/prefer-top-level-await
  .catch((error) => console.error(error));
