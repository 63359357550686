import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {filter, map, switchMap} from 'rxjs/operators';
import {ROUTER_NAVIGATED, RouterState} from '@ngrx/router-store';
import {viewRecipePageActions} from '../actions/view-recipe-page.actions';
import {Store} from '@ngrx/store';
import {selectRouteId} from '@app/store/selectors/router.selector';

@Injectable()
export class RouterEffects {
  dispatchRecipeViewedEvent$ = createEffect(() => this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      switchMap(() => this.store.select(selectRouteId as any)),
      map(Number),
      filter(id => !Number.isNaN(id)),
      map(id => viewRecipePageActions.recipeViewed({ recipeId: id, viewedAt: new Date() }))
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<RouterState>
  ) {}
}
